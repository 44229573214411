<template>
  <div class="notFound">
    <div class="container">
      <div class="row mt-3">
        <div class="col-sm-12 text-center">
          <h1 class="gavc-h1">
            404 Not Found !
          </h1>
          <p class="gavc-chapo">
            Oups, cette page n'existe pas ou n'existe plus. <br>
            Nous vous prions de nous excuser pour la gène occasionnée.
          </p>

          <div class="row" style="margin-top: 30px;">
            <div class="col-12 text-center">
              <button
                class="mt-2 gavc-btn"
                @click="$router.push('/')">
                Retour à l'accueil
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notFound',
}
</script>
